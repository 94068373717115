import {
  Box,
  Button,
  Container,
  TextField,
  Switch,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumb from "../../components/BreadCrumb";
import Offer from "../../components/offer/offer";
import {
  BillingInfos,
  Hoster,
  SubscriptionStatus,
} from "../../interfaces/Hoster";
import { offersGWM } from "../../offers/offers";
import { SlugChoice } from "../../interfaces/Types";
import {
  useHosterControllerFindOneQuery,
  useHosterControllerUpdateMutation,
} from "../../reduxtk/hoster";
import { RedirectionAPI } from "../../libs/redirectAPI";
import { DateTime } from "luxon";

const HostersEditView = () => {
  let params = useParams();
  const hosterId = params.hosterId as string;
  const initialHoster: Partial<Hoster> = {
    name: "",
    slug: "",
    subscriptionStatus: {
      _id: "",
      isEmailSubscribed: false,
      isFacturationPMSSubscribed: false,
      isFormulaireSubscribed: false,
      isGuestAppSubscribed: false,
      isPremiumSubscribed: false,
      isRoomServiceSubscribed: false,
      isOrdersSubscribed: false,
      isReviewsSubscribed: false,
      isVOIPSubscribed: false,
      isPoliceRecordSubscribed: false,
    },
    status: "",
    billingInfo: {
      isAutomaticBilling: false,
      clientId: "",
    },
  };

  const { currentData } = useHosterControllerFindOneQuery({
    id: hosterId,
  });
  const [updateHoster] = useHosterControllerUpdateMutation({
    fixedCacheKey: "shared-update-post",
  });

  const [hoster, setHoster] = useState<Partial<Hoster>>(initialHoster);
  const [prevSlug, setPrevSlug] = useState<string | undefined>(undefined);
  const [showSlugChoice, setChoice] = useState<boolean>(false);
  const [finalChoice, setFinalChoice] = useState<SlugChoice | null>(null);
  const [isAutomaticBilling, setIsAutomaticBilling] = useState<boolean>();

  useEffect(() => {
    if (currentData) {
      setIsAutomaticBilling(
        (currentData as unknown as Hoster).billingInfo.isAutomaticBilling
      );
      setHoster(currentData as unknown as Hoster);
      setPrevSlug((currentData as unknown as Hoster).slug);
    }
  }, [currentData]);

  const createNewQrDataURL = (
    qrdata: string | undefined,
    slug: string
  ): string => {
    console.log("slug:", slug);
    return qrdata!.split("/").slice(0, -1).join("/") + `/${slug}`;
  };

  const setState = (key: string, value: any) => {
    setHoster({
      ...hoster,
      qrdata:
        key === "slug"
          ? createNewQrDataURL(hoster.qrdata, value as string)
          : hoster.qrdata,
      [key]: value,
    });
  };

  const sendRequest = async (newData: Hoster) => {
    const redirectionApi: RedirectionAPI = new RedirectionAPI();
    const updateHosterDto: Hoster = { ...newData, ...hoster };
    await redirectionApi.createRedirectionUrl(updateHosterDto);
    if (finalChoice === SlugChoice.REPLACE) {
      // update the previous slug redirection url too
      console.log("comparaison2", prevSlug, updateHosterDto.slug);
      await redirectionApi.updateRedirectionUrl(
        prevSlug,
        `${process.env.REACT_APP_REDIRECT_ROOT_URL}/${updateHosterDto.slug}`
      );
    } else {
      // delete the previous slug value
      console.log("comparaison", prevSlug, updateHosterDto.slug);
      await redirectionApi.deleteRedirectionUrl(prevSlug);
    }
  };

  const onSave = async () => {
    const newData: Hoster = { ...(currentData as unknown as Hoster) };
    const newBillingInfo: BillingInfos = {
      isAutomaticBilling: hoster.billingInfo!.isAutomaticBilling,
      clientId: hoster.billingInfo?.isAutomaticBilling
        ? hoster.billingInfo?.clientId
        : undefined,
    };
    toast.promise(
      updateHoster({
        id: hosterId,
        updateHosterDto: { ...newData, ...hoster, billingInfo: newBillingInfo },
      }),
      {
        pending: "Mise à jour en cours...",
        success: "Opération réalisée avec succès 👌",
        error: "L'opération a échoué 🤯",
      }
    );
    if (finalChoice !== null) {
      await toast.promise(sendRequest(newData), {
        pending: "Mise à jour en base de données en cours...",
        success: "Opération en base de données réalisées avec succès 👌",
        error: "Les opérations ont échoués 🤯",
      });
    }
    setChoice(false);
    setFinalChoice(null);
  };

  const resetStats = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const currentDate = DateTime.now().toISO();
    const newData: Hoster = {
      ...(currentData as unknown as Hoster),
      analysisStartedAt: currentDate,
    };
    toast.promise(
      updateHoster({
        id: hosterId,
        updateHosterDto: newData,
      }),
      {
        pending: "Mise à jour en cours...",
        success: "Opération réalisée avec succès 👌",
        error: "L'opération a échoué 🤯",
      }
    );
    if (finalChoice !== null) {
      await toast.promise(sendRequest(newData), {
        pending: "Mise à jour en base de données en cours...",
        success: "Opération en base de données réalisées avec succès 👌",
        error: "Les opérations ont échoués 🤯",
      });
    }
  };

  const handleChangeSubscription = (
    key: string = "",
    value: boolean = false
  ) => {
    const currentSubscriptionStatus: SubscriptionStatus =
      hoster.subscriptionStatus as SubscriptionStatus;
    const newSubscriptionStatus: SubscriptionStatus = {
      ...currentSubscriptionStatus,
      [key]: value,
    };
    setHoster({
      ...hoster,
      subscriptionStatus: newSubscriptionStatus,
    });
  };

  const handleBilling = (key: string = "", value: any) => {
    const currentBillingInfo: BillingInfos = hoster.billingInfo as BillingInfos;
    const newBillingInfo: BillingInfos = {
      ...currentBillingInfo,
      [key]: value,
    };
    setHoster({
      ...hoster,
      billingInfo: newBillingInfo,
    });
  };

  return (
    <Container maxWidth="xl">
      <BreadCrumb />
      {currentData ? (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "70ch" },
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            id="name"
            label="Nom"
            value={hoster.name}
            onChange={(e) => setState("name", e.target.value)}
          />

          <TextField
            required
            id="slug"
            label="Slug"
            value={hoster.slug}
            onChange={(e) => {
              setState("slug", e.target.value);
              setChoice(prevSlug !== e.target.value);
            }}
          />
          {showSlugChoice && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "15px 10px",
              }}
            >
              <span style={{ marginBottom: "10px", alignContent: "center" }}>
                Le QR code change. Un nouveau QR code va être généré avec le
                nouveau slug.
                <br />
                <br />
                Souhaitez-vous que l’ancien QR code :
              </span>
              <FormControlLabel
                control={
                  <Radio
                    checked={finalChoice === SlugChoice.REPLACE}
                    onChange={() => setFinalChoice(SlugChoice.REPLACE)}
                  ></Radio>
                }
                label={
                  "soit conservé et redirige vers le nouveau slug (s’il est déjà affiché dans l’hotel)"
                }
                required
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={finalChoice === SlugChoice.DELETE}
                    onChange={() => setFinalChoice(SlugChoice.DELETE)}
                  ></Radio>
                }
                label={"soit définitivement supprimé (s’il n’est plus utilisé)"}
                required
              />
            </div>
          )}
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={hoster.status}
              label="Status"
              //onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}
          <div>
            <Switch
              name="isAutomaticBilling"
              checked={isAutomaticBilling}
              onClick={() => {
                setIsAutomaticBilling(!isAutomaticBilling);
                handleBilling("isAutomaticBilling", !isAutomaticBilling);
              }}
            />
            <span>Facturation automatique</span>
          </div>
          {isAutomaticBilling && (
            <TextField
              required
              id="facturationID"
              label="ID Stripe du client"
              value={hoster.billingInfo?.clientId}
              onChange={(e) => handleBilling("clientId", e.target.value)}
            />
          )}
          <Offer
            hoster={hoster}
            setHoster={setHoster}
            onChange={handleChangeSubscription}
            items={offersGWM}
          />
        </Box>
      ) : (
        "Error: Hoster undefined."
      )}
      <Container>
        <Button
          variant="contained"
          onClick={onSave}
          disabled={prevSlug !== hoster.slug && finalChoice === null}
          sx={{ marginTop: "20px" }}
        >
          Sauvegarder les changements
        </Button>
        <Button
          variant="contained"
          onClick={resetStats}
          disabled={prevSlug !== hoster.slug && finalChoice === null}
          sx={{ marginTop: "20px", marginLeft: "20px" }}
        >
          Remettre les stats à 0
        </Button>
      </Container>
    </Container>
  );
};

export default HostersEditView;
