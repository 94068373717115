import { SubscriptionStatus } from '../../interfaces/Hoster';
import { Offers } from '../types/offers.enum';

export class OfferType {
  static getOfferType(subscriptionStatus: SubscriptionStatus): Offers | null {
    if (!subscriptionStatus) return null;
    if (this.isExperienceOffer(subscriptionStatus)) return Offers.Experience;
    else if (this.isUpsellOffer(subscriptionStatus)) return Offers.Upsell;
    else if (subscriptionStatus?.isGuestAppSubscribed) return Offers.Communication;
    else return Offers.Custom;
  }

  private static isExperienceOffer(subscriptionStatus: SubscriptionStatus): boolean {
    const expOffer = [
      'isEmailSubscribed',
      'isFacturationPMSSubscribed',
      'isFormulaireSubscribed',
      'isGuestAppSubscribed',
      'isPremiumSubscribed',
      'isRoomServiceSubscribed',
      'isOrdersSubscribed',
      'isReviewsSubscribed',
    ];
    return this.isOffer(subscriptionStatus, expOffer);
  }

  private static isUpsellOffer(subscriptionStatus: SubscriptionStatus): boolean {
    const upOffer = ['isGuestAppSubscribed', 'isRoomServiceSubscribed', 'isOrdersSubscribed'];
    return this.isOffer(subscriptionStatus, upOffer);
  }
  private static isCommunicationOffer(subscriptionStatus: SubscriptionStatus): boolean {
    const comOffer = ['isGuestAppSubscribed'];
    return this.isOffer(subscriptionStatus, comOffer);
  }
  private static isOffer(subscriptionStatus: SubscriptionStatus, offer: string[]): boolean {
    const keys = Object.keys(subscriptionStatus) as [keyof SubscriptionStatus];

    for (const key of keys) {
      if (offer.includes(key) && !subscriptionStatus[key]) return false;
    }
    return true;
  }

  static getSubscriptionStatusFromOfferType(
    offerType: Offers,
    origin: SubscriptionStatus | undefined
  ): SubscriptionStatus | undefined {
    if (!origin) return undefined;
    const newSubscriptionStatus: SubscriptionStatus = {
      ...origin,
      isEmailSubscribed: false,
      isFacturationPMSSubscribed: false,
      isFormulaireSubscribed: false,
      isGuestAppSubscribed: false,
      isPremiumSubscribed: false,
      isRoomServiceSubscribed: false,
      isOrdersSubscribed: false,
      isReviewsSubscribed: false,
    };
    if (offerType === Offers.Communication) newSubscriptionStatus.isGuestAppSubscribed = true;
    else if (offerType === Offers.Upsell) {
      newSubscriptionStatus.isGuestAppSubscribed = true;
      newSubscriptionStatus.isRoomServiceSubscribed = true;
      newSubscriptionStatus.isOrdersSubscribed = true;
    } else if (offerType === Offers.Experience) {
      newSubscriptionStatus.isEmailSubscribed = true;
      newSubscriptionStatus.isFacturationPMSSubscribed = true;
      newSubscriptionStatus.isFormulaireSubscribed = true;
      newSubscriptionStatus.isGuestAppSubscribed = true;
      newSubscriptionStatus.isPremiumSubscribed = true;
      newSubscriptionStatus.isRoomServiceSubscribed = true;
      newSubscriptionStatus.isOrdersSubscribed = true;
      newSubscriptionStatus.isReviewsSubscribed = true;
    }
    return newSubscriptionStatus;
  }
}
