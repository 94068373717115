import { IDetailsOffer } from "../interfaces/detailOffer";

const details = {
  voip: {
    id: "voip",
    name: "VOIP",
    description: "",
    active: false,
    externalId: "isVOIPSubscribed",
    static: false,
  },
  presse: {
    id: "presse",
    name: "Presse",
    description: "",
    active: false,
    externalId: "",
    static: false,
  },
  chatbot: {
    id: "chatbot",
    name: "Chatbot",
    description: "",
    active: false,
    externalId: "",
    static: false,
  },
  guest_app: {
    id: "guest_app",
    name: "Guest App",
    description: "",
    active: false,
    externalId: "isGuestAppSubscribed",
    static: true,
  },
  police_record: {
    id: "police_record",
    name: "Fiches de police",
    description: "",
    active: false,
    externalId: "isPoliceRecordSubscribed",
    static: false,
  },
};

export const commonDetails: IDetailsOffer[] = [
  details.voip,
  details.presse,
  details.chatbot,
  details.guest_app,
  details.police_record,
];
