import { IDetailsOffer } from '../interfaces/detailOffer';
import { details } from './details';

export const communicationDetail: IDetailsOffer[] = [];

export const upsellDetail: IDetailsOffer[] = [details.room_service, details.orders];

export const clientExperienceDetail: IDetailsOffer[] = [
  details.room_service,
  details.email_automation,
  details.form,
  details.facturation_PMS,
  details.orders,
  details.reviews,
  details.premium,
];
