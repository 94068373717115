import { commonDetails } from './common/commonDetails';
import { details } from './common/details';
import { clientExperienceDetail, communicationDetail, upsellDetail } from './common/offerDetails';
import { IOffer } from './interfaces/offer';
import { Offers } from './types/offers.enum';

export const offersGWM: IOffer[] = [
  {
    id: Offers.Communication,
    name: Offers.Communication,
    description: `Offre ${Offers.Communication}`,
    details: [...commonDetails, ...communicationDetail],
  },
  {
    id: Offers.Upsell,
    name: Offers.Upsell,
    description: `Offre ${Offers.Upsell}`,
    details: [...commonDetails, ...upsellDetail],
  },
  {
    id: Offers.Experience,
    name: Offers.Experience,
    description: `Offre ${Offers.Experience}`,
    details: [...commonDetails, ...clientExperienceDetail],
  },
  {
    id: Offers.Custom,
    name: Offers.Custom,
    description: `Offre ${Offers.Custom}`,
    details: [...commonDetails, ...Object.values(details)],
  },
];
