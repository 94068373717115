import { IDetailsOffer } from '../interfaces/detailOffer';

export const details: { [key: string]: IDetailsOffer } = {
  room_service: {
    id: 'room_service',
    name: 'Room Service',
    description: '',
    active: false,
    externalId: 'isRoomServiceSubscribed',
    static: true,
  },
  email_automation: {
    id: 'email_automation',
    name: "Automatisation de l'envoi d'email",
    description: '',
    active: false,
    externalId: 'isEmailSubscribed',
    static: true,
  },
  form: {
    id: 'form',
    name: 'Formulaire',
    description: '',
    active: false,
    externalId: 'isFormulaireSubscribed',
    static: true,
  },
  facturation_PMS: {
    id: 'facturation_PMS',
    name: 'Facturation PMS',
    description: '',
    active: false,
    externalId: 'isFacturationPMSSubscribed',
    static: true,
  },
  orders: {
    id: 'orders',
    name: 'Orders',
    description: '',
    active: false,
    externalId: 'isOrdersSubscribed',
    static: true,
  },
  reviews: {
    id: 'reviews',
    name: 'Reviews',
    description: '',
    active: false,
    externalId: 'isReviewsSubscribed',
    static: true,
  },
  premium: {
    id: 'premium',
    name: 'Premium',
    description: '',
    active: false,
    static: true,
  },
};
